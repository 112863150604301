import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const inputDefaultVariants = {}
const inputCompoundVariants = []

const inputSlotNames = [
  [
    "root",
    "kc-input__root"
  ],
  [
    "prefix",
    "kc-input__prefix"
  ],
  [
    "affix",
    "kc-input__affix"
  ],
  [
    "icon",
    "kc-input__icon"
  ],
  [
    "wrapper",
    "kc-input__wrapper"
  ]
]
const inputSlotFns = /* @__PURE__ */ inputSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, inputDefaultVariants, getSlotCompoundVariant(inputCompoundVariants, slotName))])

const inputFn = memo((props = {}) => {
  return Object.fromEntries(inputSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const inputVariantKeys = [
  "readonly",
  "disabled",
  "error"
]

export const input = /* @__PURE__ */ Object.assign(inputFn, {
  __recipe__: false,
  __name__: 'input',
  raw: (props) => props,
  variantKeys: inputVariantKeys,
  variantMap: {
  "readonly": [
    "true"
  ],
  "disabled": [
    "true"
  ],
  "error": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, inputVariantKeys)
  },
})