import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const videoDefaultVariants = {}
const videoCompoundVariants = []

const videoSlotNames = [
  [
    "wrapper",
    "video__wrapper"
  ],
  [
    "root",
    "video__root"
  ]
]
const videoSlotFns = /* @__PURE__ */ videoSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, videoDefaultVariants, getSlotCompoundVariant(videoCompoundVariants, slotName))])

const videoFn = memo((props = {}) => {
  return Object.fromEntries(videoSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const videoVariantKeys = []

export const video = /* @__PURE__ */ Object.assign(videoFn, {
  __recipe__: false,
  __name__: 'video',
  raw: (props) => props,
  variantKeys: videoVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, videoVariantKeys)
  },
})