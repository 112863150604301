import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const alertDefaultVariants = {
  "variant": "info"
}
const alertCompoundVariants = []

const alertSlotNames = [
  [
    "root",
    "alert__root"
  ],
  [
    "title",
    "alert__title"
  ],
  [
    "description",
    "alert__description"
  ]
]
const alertSlotFns = /* @__PURE__ */ alertSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, alertDefaultVariants, getSlotCompoundVariant(alertCompoundVariants, slotName))])

const alertFn = memo((props = {}) => {
  return Object.fromEntries(alertSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const alertVariantKeys = [
  "variant"
]

export const alert = /* @__PURE__ */ Object.assign(alertFn, {
  __recipe__: false,
  __name__: 'alert',
  raw: (props) => props,
  variantKeys: alertVariantKeys,
  variantMap: {
  "variant": [
    "info",
    "error"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, alertVariantKeys)
  },
})