import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const layoutGridFn = /* @__PURE__ */ createRecipe('layout-grid', {}, [])

const layoutGridVariantMap = {}

const layoutGridVariantKeys = Object.keys(layoutGridVariantMap)

export const layoutGrid = /* @__PURE__ */ Object.assign(memo(layoutGridFn), {
  __recipe__: true,
  __name__: 'layoutGrid',
  raw: (props) => props,
  variantKeys: layoutGridVariantKeys,
  variantMap: layoutGridVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, layoutGridVariantKeys)
  },
})