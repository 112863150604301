import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsLinkServerDefaultVariants = {}
const cmsLinkServerCompoundVariants = []

const cmsLinkServerSlotNames = [
  [
    "root",
    "cmsLinkServer__root"
  ],
  [
    "navLink",
    "cmsLinkServer__navLink"
  ],
  [
    "socialLink",
    "cmsLinkServer__socialLink"
  ]
]
const cmsLinkServerSlotFns = /* @__PURE__ */ cmsLinkServerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsLinkServerDefaultVariants, getSlotCompoundVariant(cmsLinkServerCompoundVariants, slotName))])

const cmsLinkServerFn = memo((props = {}) => {
  return Object.fromEntries(cmsLinkServerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsLinkServerVariantKeys = []

export const cmsLinkServer = /* @__PURE__ */ Object.assign(cmsLinkServerFn, {
  __recipe__: false,
  __name__: 'cmsLinkServer',
  raw: (props) => props,
  variantKeys: cmsLinkServerVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsLinkServerVariantKeys)
  },
})