import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const alertDialogDefaultVariants = {}
const alertDialogCompoundVariants = []

const alertDialogSlotNames = [
  [
    "overlay",
    "alertDialog__overlay"
  ],
  [
    "content",
    "alertDialog__content"
  ],
  [
    "header",
    "alertDialog__header"
  ],
  [
    "footer",
    "alertDialog__footer"
  ],
  [
    "title",
    "alertDialog__title"
  ],
  [
    "description",
    "alertDialog__description"
  ],
  [
    "cancelAction",
    "alertDialog__cancelAction"
  ]
]
const alertDialogSlotFns = /* @__PURE__ */ alertDialogSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, alertDialogDefaultVariants, getSlotCompoundVariant(alertDialogCompoundVariants, slotName))])

const alertDialogFn = memo((props = {}) => {
  return Object.fromEntries(alertDialogSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const alertDialogVariantKeys = []

export const alertDialog = /* @__PURE__ */ Object.assign(alertDialogFn, {
  __recipe__: false,
  __name__: 'alertDialog',
  raw: (props) => props,
  variantKeys: alertDialogVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, alertDialogVariantKeys)
  },
})