import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const textFn = /* @__PURE__ */ createRecipe('text', {
  "variant": "block"
}, [])

const textVariantMap = {
  "variant": [
    "block",
    "block-sm",
    "block-xs",
    "teaser",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6"
  ],
  "leadingNormal": [
    "true"
  ]
}

const textVariantKeys = Object.keys(textVariantMap)

export const text = /* @__PURE__ */ Object.assign(memo(textFn), {
  __recipe__: true,
  __name__: 'text',
  raw: (props) => props,
  variantKeys: textVariantKeys,
  variantMap: textVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, textVariantKeys)
  },
})