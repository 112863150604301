/* eslint-disable @typescript-eslint/naming-convention */

import React from 'react';

import { css, cx } from '@pt-frontends/styled-system/css';
import { ButtonVariantProps, button } from '@pt-frontends/styled-system/recipes';
import { Slot } from '@radix-ui/react-slot';

import { Icon, IconType } from '@ui/icon';
import { Loader } from '@ui/loader';

type ButtonIconPlacement = 'before' | 'after';

type ButtonVariant = ButtonVariantProps['variant'];

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ButtonVariantProps {
  icon?: IconType;
  iconPlacement?: ButtonIconPlacement;
  srOnly?: string | React.ReactNode;
  loading?: boolean;
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    variant = 'primary',
    type = 'button',
    className,
    size = 'default',
    children,
    icon,
    iconPlacement = 'after',
    loading,
    noIconTransform,
    disabled,
    srOnly,
    asChild,
    isDisabled,
    ...restProps
  } = props;

  const Comp = asChild ? Slot : 'button';
  const cl = button({ variant, size, isDisabled: disabled || isDisabled, noIconTransform });

  return (
    <Comp
      ref={ref}
      className={cx(cl.root, className, `btn-${variant}`, `btn-${size}`, 'group')}
      disabled={disabled}
      type={type}
      {...restProps}
    >
      {icon && iconPlacement === 'before' && <Icon className={cl.icon} i={icon} size="small" />}
      <span className={cx('btn-label', cl.label)}>{children}</span>
      {icon && iconPlacement === 'after' && !loading && (
        <Icon className={cx('btn-icon', cl.icon)} i={icon} size="small" />
      )}
      {loading && <Loader size={18} className={cl.loader} />}
      {/* scren readers only */}
      {srOnly && <span className={cx('sr-only', css({ srOnly: true }))}>{srOnly}</span>}
    </Comp>
  );
});
Button.displayName = 'Button';

export { Button };
export type { ButtonIconPlacement, ButtonProps, ButtonVariant };
