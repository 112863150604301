import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const labelDefaultVariants = {}
const labelCompoundVariants = []

const labelSlotNames = [
  [
    "root",
    "label__root"
  ],
  [
    "requiredStar",
    "label__requiredStar"
  ]
]
const labelSlotFns = /* @__PURE__ */ labelSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, labelDefaultVariants, getSlotCompoundVariant(labelCompoundVariants, slotName))])

const labelFn = memo((props = {}) => {
  return Object.fromEntries(labelSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const labelVariantKeys = [
  "disabled",
  "error"
]

export const label = /* @__PURE__ */ Object.assign(labelFn, {
  __recipe__: false,
  __name__: 'label',
  raw: (props) => props,
  variantKeys: labelVariantKeys,
  variantMap: {
  "disabled": [
    "true"
  ],
  "error": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, labelVariantKeys)
  },
})