import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const formDefaultVariants = {}
const formCompoundVariants = []

const formSlotNames = [
  [
    "item",
    "form__item"
  ],
  [
    "description",
    "form__description"
  ],
  [
    "message",
    "form__message"
  ],
  [
    "clearBtn",
    "form__clearBtn"
  ],
  [
    "clearBtnIcon",
    "form__clearBtnIcon"
  ]
]
const formSlotFns = /* @__PURE__ */ formSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, formDefaultVariants, getSlotCompoundVariant(formCompoundVariants, slotName))])

const formFn = memo((props = {}) => {
  return Object.fromEntries(formSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const formVariantKeys = [
  "error"
]

export const form = /* @__PURE__ */ Object.assign(formFn, {
  __recipe__: false,
  __name__: 'form',
  raw: (props) => props,
  variantKeys: formVariantKeys,
  variantMap: {
  "error": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, formVariantKeys)
  },
})