import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const checkboxGroupFn = /* @__PURE__ */ createRecipe('checkbox-group', {}, [])

const checkboxGroupVariantMap = {
  "layout": [
    "horizontal",
    "vertical"
  ]
}

const checkboxGroupVariantKeys = Object.keys(checkboxGroupVariantMap)

export const checkboxGroup = /* @__PURE__ */ Object.assign(memo(checkboxGroupFn), {
  __recipe__: true,
  __name__: 'checkboxGroup',
  raw: (props) => props,
  variantKeys: checkboxGroupVariantKeys,
  variantMap: checkboxGroupVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, checkboxGroupVariantKeys)
  },
})