import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const cmsEditorTextFn = /* @__PURE__ */ createRecipe('cms-editor-text', {
  "variant": "blockLg"
}, [])

const cmsEditorTextVariantMap = {
  "variant": [
    "blockLg",
    "blockSm",
    "blockXs"
  ],
  "table": [
    "true"
  ]
}

const cmsEditorTextVariantKeys = Object.keys(cmsEditorTextVariantMap)

export const cmsEditorText = /* @__PURE__ */ Object.assign(memo(cmsEditorTextFn), {
  __recipe__: true,
  __name__: 'cmsEditorText',
  raw: (props) => props,
  variantKeys: cmsEditorTextVariantKeys,
  variantMap: cmsEditorTextVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, cmsEditorTextVariantKeys)
  },
})