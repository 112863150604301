import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const linkBoxedDefaultVariants = {}
const linkBoxedCompoundVariants = []

const linkBoxedSlotNames = [
  [
    "box",
    "link-boxed__box"
  ],
  [
    "boxIcon",
    "link-boxed__boxIcon"
  ],
  [
    "label",
    "link-boxed__label"
  ],
  [
    "linkIcon",
    "link-boxed__linkIcon"
  ]
]
const linkBoxedSlotFns = /* @__PURE__ */ linkBoxedSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, linkBoxedDefaultVariants, getSlotCompoundVariant(linkBoxedCompoundVariants, slotName))])

const linkBoxedFn = memo((props = {}) => {
  return Object.fromEntries(linkBoxedSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const linkBoxedVariantKeys = []

export const linkBoxed = /* @__PURE__ */ Object.assign(linkBoxedFn, {
  __recipe__: false,
  __name__: 'linkBoxed',
  raw: (props) => props,
  variantKeys: linkBoxedVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, linkBoxedVariantKeys)
  },
})