import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const menuButtonDefaultVariants = {}
const menuButtonCompoundVariants = []

const menuButtonSlotNames = [
  [
    "root",
    "menuButton__root"
  ],
  [
    "inner",
    "menuButton__inner"
  ],
  [
    "line",
    "menuButton__line"
  ]
]
const menuButtonSlotFns = /* @__PURE__ */ menuButtonSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, menuButtonDefaultVariants, getSlotCompoundVariant(menuButtonCompoundVariants, slotName))])

const menuButtonFn = memo((props = {}) => {
  return Object.fromEntries(menuButtonSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const menuButtonVariantKeys = [
  "open"
]

export const menuButton = /* @__PURE__ */ Object.assign(menuButtonFn, {
  __recipe__: false,
  __name__: 'menuButton',
  raw: (props) => props,
  variantKeys: menuButtonVariantKeys,
  variantMap: {
  "open": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, menuButtonVariantKeys)
  },
})