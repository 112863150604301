import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const layoutBoxFn = /* @__PURE__ */ createRecipe('layout-box', {}, [])

const layoutBoxVariantMap = {}

const layoutBoxVariantKeys = Object.keys(layoutBoxVariantMap)

export const layoutBox = /* @__PURE__ */ Object.assign(memo(layoutBoxFn), {
  __recipe__: true,
  __name__: 'layoutBox',
  raw: (props) => props,
  variantKeys: layoutBoxVariantKeys,
  variantMap: layoutBoxVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, layoutBoxVariantKeys)
  },
})