import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const switchxDefaultVariants = {}
const switchxCompoundVariants = []

const switchxSlotNames = [
  [
    "wrapper",
    "switch__wrapper"
  ],
  [
    "root",
    "switch__root"
  ],
  [
    "label",
    "switch__label"
  ],
  [
    "thumb",
    "switch__thumb"
  ],
  [
    "isOnLabel",
    "switch__isOnLabel"
  ]
]
const switchxSlotFns = /* @__PURE__ */ switchxSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, switchxDefaultVariants, getSlotCompoundVariant(switchxCompoundVariants, slotName))])

const switchxFn = memo((props = {}) => {
  return Object.fromEntries(switchxSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const switchxVariantKeys = [
  "active"
]

export const switchx = /* @__PURE__ */ Object.assign(switchxFn, {
  __recipe__: false,
  __name__: 'switchx',
  raw: (props) => props,
  variantKeys: switchxVariantKeys,
  variantMap: {
  "active": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, switchxVariantKeys)
  },
})