import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const imageViewerDefaultVariants = {}
const imageViewerCompoundVariants = []

const imageViewerSlotNames = [
  [
    "trigger",
    "image-viewer__trigger"
  ],
  [
    "button",
    "image-viewer__button"
  ],
  [
    "content",
    "image-viewer__content"
  ],
  [
    "icon",
    "image-viewer__icon"
  ],
  [
    "iconBig",
    "image-viewer__iconBig"
  ],
  [
    "overlay",
    "image-viewer__overlay"
  ],
  [
    "body",
    "image-viewer__body"
  ],
  [
    "header",
    "image-viewer__header"
  ]
]
const imageViewerSlotFns = /* @__PURE__ */ imageViewerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, imageViewerDefaultVariants, getSlotCompoundVariant(imageViewerCompoundVariants, slotName))])

const imageViewerFn = memo((props = {}) => {
  return Object.fromEntries(imageViewerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const imageViewerVariantKeys = []

export const imageViewer = /* @__PURE__ */ Object.assign(imageViewerFn, {
  __recipe__: false,
  __name__: 'imageViewer',
  raw: (props) => props,
  variantKeys: imageViewerVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, imageViewerVariantKeys)
  },
})